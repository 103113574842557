.pic{
    width: 20rem;
}
.logo{
    width: 10rem;
    margin-top: -1rem;
    margin-left: -3rem;
}
.social{
    margin-left: 0.3rem;
}
.blogs-grid{
grid-template-columns: repeat(3, minmax(calc(10rem + 15vw), 1fr));
grid-gap: calc(1rem + 2vw);
}
.anchor{
    top: 0;
    right: 0.5rem;
}
.workflow{
    left: calc(10rem + 15vw);

}
/* .bar{
    width: 30rem;
    margin-left: 12rem;
    margin-top: 3.5rem;
} */
@media screen and (max-width: 1268px){
    .blogs-grid{
        grid-template-columns: repeat(2, minmax(calc(10rem + 15vw), 1fr));
        grid-gap: calc(1rem + 2vw);
        }
    }
@media screen and (max-width: 568px){
    .pic{
        width: 10rem;
        margin-bottom: 0.2rem;
        height: 20rem;
    }
    .logo{
        width: 7rem;
        margin-left: -3rem;
        margin-top: -0.4rem;

    }
    .social{
        margin-left: -1rem;
    }
    .introtext{
        color: blue;
        font-size: 0.5rem;
    }
    .socialabout{
        margin-left:2rem;
    }
    .blogs-grid{
        grid-template-columns: repeat(1, minmax(calc(10rem + 15vw), 1fr));
        grid-gap: calc(1rem + 2vw);
        }
        .anchor{
            top: 0;
            right: 0.1rem;
        }
        .workflow{
            left: calc(3rem + 2vw);
        
        }
    /* .bar{
        margin-top: 2.8rem;
        margin-right: 0rem;
    } */
}

